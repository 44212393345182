import store from '@/store/'
import {getStorageToken} from "@/mixins/Auth";

export const getUserInfo = () => {
    let token = getStorageToken();
    if(token === null) {
        return;
    }
    if(store.getters['userInfo/getUserInfo'] != undefined && store.getters['userInfo/getUserInfo'].hasOwnProperty('mb_no') && store.getters['userInfo/getUserInfo'].mb_no){
        return;
    }
    store.dispatch( 'userInfo/setUserInfo', {token : token}  );
};
