import Api from '@/mixins/list/api';
export default {
    data() {
        return {
            List: [],
            listClass : null,

            paginationType: '',
            hasPre: false,

        }
    },
    methods: {
        initList(type, options) {
            switch (type) {
                case 'api':
                    this.listClass = new Api(options);
                    break;
            }
        },
        getList(isFirst=false) {
            let filterSet = JSON.parse(JSON.stringify(this.getFilterSet()));
            if(isFirst && this.paginationType === 'lazyload') {
                let parsingDataPage = this.getPasingDataPage();
                let size = filterSet.page.size;
                // filterSet.page.page = parsingDataPage;
                filterSet.page.page = 1;
                filterSet.page.size = parsingDataPage * size;
                this.List = [];
            }else if(isFirst && this.paginationType != 'lazyload') {
                let parsingDataPage = this.getPasingDataPage();
                filterSet.page.page = parsingDataPage;
                this.List = [];
            }else if(this.isInitPage === true) {
                filterSet.page.page = 1;
                this.filterSet.page.page = 1;
            }
            if(this.listClass === null) {
                return false;
            }
            this.listClass.getList(filterSet).then(res => {
                let list = res.list.slice();
                if(this.paginationType === 'lazyload' && this.List.length > 0 && this.hasPre) {
                    this.List = list.concat(this.List);
                }else if(this.paginationType === 'lazyload' && filterSet.page.page > 1) {
                    this.List.splice(filterSet.page.page -1 * filterSet.page.size, filterSet.page.size);
                    this.List = this.List.concat(list);
                }else{
                    this.List = list;
                }
                if(res.hasOwnProperty('total')) {
                    this.setPage('total', res.total);
                }
                if(isFirst) {
                    this.setParsingDataPageFilterSet();
                }
                if(this.isInitPage === true) {
                    this.setPage('page', 1);
                }
                this.isInitPage = false;
            })
        },
    },
    watch: {
    }
}