export const routerLevelObj = {
    // 'HomeUpdate' : [2],
    // 'CommunityWrite': [2],
    // 'Community' : [0, 1],
    // 'CommunityDetail' : [0, 1],
    // 'Letter': [1],
    // 'LetterDetail': [1],
    // 'MyPage' : [1, 2],
    // 'MyPageLetterDetail' : [1],
    // 'MyPageCommunityDetail' : [1, 2],
    // 'MyPageCommunityUpdate' : [2],
    // 'MypageProfile' : [1, 2],
}