import {request,setAuthInHeader} from "@/api/request";
import {logout} from "@/mixins/Auth";
import Swal from "sweetalert2";

import {getAlarmListFunc} from "@/mixins/alarmMixins";
const state = {
    UserInfo: {},
};

const getters  = {
    getUserInfo: state => state.UserInfo,
};

const mutations = {
    updateUserInfo(state,obj){
        state.UserInfo = obj.value;
    },
    resetUserInfo(state){
        state.UserInfo = {};
    },
};

const actions = {
    setUserInfo({ commit, rootState }, { token }) {
        setAuthInHeader(token);
        let params = {
            m_type : 'self',
            opf:1,
            opd: 1,
        }
        request.get('userinfo', params)
            .then( (res) => {
                let result = res.data.Data.Result;
                if(result.Info.type > 0 && result.List.length > 0){
                    getAlarmListFunc();
                    commit('updateUserInfo', {'value' : result.List[0]});
                }else{
                    commit('updateUserInfo', {'value' : {}});
                    // logout();
                    if(result.Info.type === -99) {
                        Swal.fire({
                            title: '',
                            html: '관리자가 승인거부 하였습니다.',
                            confirmButtonText: '확인',
                            focusConfirm:false,
                            customClass :{
                                popup:'confirmPopup',
                                content :'confirmContent',
                                actions:'confirmAction',
                                confirmButton:'alertConfirm',
                            },
                            //check면 chk_alert 클래스를 주세요.
                            onClose: () => {
                                logout();
                            }
                        })
                    }else{
                        logout();
                    }
                }

            }, () => {
                console.log('error');
            })
    },
    changeUserInfo({commit, rootState}, {data}) {
        commit('updateUserInfo', {'value' : data});
    },
    initUserInfo({commit, rootState }, {data}) {
        commit('resetUserInfo');
    },
};

export default { namespaced: true, state, getters, mutations, actions }
