import Vue from 'vue';
import SimpleVueValidation from 'simple-vue-validator';
const Validator = SimpleVueValidation.Validator;

Vue.use(SimpleVueValidation);

export default {
    data() {
        return{
            title: '',
            content: '',
            files: [],
            deleteFiles: [],
            mentor: [],
            type: [],
            secret: 0,
        }
    },
    validators: {
        title: function (value) {
          return Validator.value(value)
              .required('제목을 입력해주세요.')
        },
        content: function (value) {
          return Validator.value(value)
              .required('내용을 입력해주세요.');
        },
        mentor: function (value) {
          return Validator.value(value)
        },
        type: function (value) {
            return Validator.value(value)
        },
        secret: function (value) {
            return Validator.value(value)
        },
    },
    watch: {},
}