import Vue from 'vue'
import Vuex from 'vuex'

import userInfo from './user/UserInfo'; //유저관리
import alarm from "./user/alarm";

Vue.use(Vuex)

const state = {
    PagePathStatus: true, //true(parent), false(child)
    PageParentBackPath : () => {},
    PageChildBackPath: () => {},
    backStatus: {
        registerBack: true
    }
};

export default new Vuex.Store({
    state,
    modules: {
        userInfo,
        alarm,
    }
})
