var Match = (function() {
	function Match() {
		this.filter = {
			key: null,
			value: null,
			classname: null
		}
		Match.prototype.setterFilter = function(key=null, value=null, classname=null) {
			this.filter.key = key;
			this.filter.value = value;
			this.filter.classname = classname;
		}
	}
	return Match;
})();
export default Match;