import store from '@/store/'
import {getStorageToken} from "@/mixins/Auth";

export const getAlarmListFunc = () => {
    let token = getStorageToken();
    if(token === null) {
        return;
    }
    //store.getters['userInfo/getUserInfo']
    let hasGet = store.getters['alarm/getHasGet'];
    if(hasGet === 0) {
        store.dispatch( 'alarm/setList', {token : token}  );
    }
};

export const initAlarmHasGet = () => {
    store.dispatch( 'alarm/initHasGet', {}  );
};