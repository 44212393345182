import util from "@/mixins//util";
import VuxAlert from "@/mixins/vux-alert";
export default {
    mixins: [VuxAlert],
    data() {
        return {
            imageMaxSize : 1024 * 1024 * 20,
            fileExceedText: '파일은 한개만 가능합니다.',
            files: [],
            deleteFiles: [],
        }
    },
    methods: {
        handleFileChange(file, fileList) {
            let maxSize = this.imageMaxSize;
            let type = file.raw.type.split('/');
            if(file.size > maxSize) {
                this.errorAlert('1회 업로드 가능 용량을 초과했습니다!');
                fileList.pop();
            }else if(!(type[1] === 'jpeg' || type[1] === 'png')) {
                this.errorAlert('이미지 파일만 업로드 가능합니다.')
                fileList.pop();
            }else{
                this.files = fileList;
                this.parentSetData('files', this.files);
                this.$forceUpdate();
            }
        },
        onlyOnePhotoChange(file, fileList) {
            let maxSize = this.imageMaxSize;
            let type = file.raw.type.split('/');
            if(file.size > maxSize) {
                this.errorAlert('1회 업로드 가능 용량을 초과했습니다!');
                fileList.pop();
            }else if(!(type[1] === 'jpeg' || type[1] === 'png')) {
                this.errorAlert('이미지 파일만 업로드 가능합니다.')
                fileList.pop();
            }else{
                if(this.files.length > 0 && this.files[0].hasOwnProperty('a_idx') && this.files[0].a_idx != null) {
                    file.a_idx = this.files[0].a_idx;
                }
                file.org_url = file.url;
                this.files[0] = file;
                this.$forceUpdate();
                this.parentSetData('files', this.files);
            }
        },
        handleFileRemove(file, fileList) {
            if(file.a_idx) {
                this.deleteFiles.push(file);
                this.parentSetData('deleteFiles', this.deleteFiles);
            }
            this.files = fileList;
            this.parentSetData('files', this.files);
        },
        handleFileExceed() {
            this.errorAlert(this.fileExceedText);
        },
        uploadFiles(files, type, id) {
            return new Promise((resolve, reject) => {
                let resultCnt = 0;
                if(files.length === 0) {
                    resolve(true)
                }
                files.forEach((file, index) => {

                    if(file.hasOwnProperty('raw')) {
                        let formData = new FormData();
                        formData.append('files', file.raw);
                        formData.append('a_container_type', type);
                        formData.append('a_container_id', id);
                        if(file.hasOwnProperty('a_idx')) {
                            formData.append('a_idx', file.a_idx);
                        }
                        try {
                            this.$api.$file.updateFile(formData).then(res => {
                                console.log(res);
                                resultCnt = resultCnt + 1;
                                if(resultCnt === files.length ) {
                                    resolve(true);
                                }
                            })
                        }catch (e) {
                            console.log(e)
                        }
                    }else{
                        resultCnt = resultCnt + 1;
                    }
                })
            })
        },
        removeFiles(files) {
            return util.Array.each(files, (file, index, arr) => {
                if(file.hasOwnProperty('a_idx') && file.a_idx != null) {
                    let params = {
                        a_idx : file.a_idx
                    }
                    this.$api.$file.deleteFile(params).then(res => {
                        console.log(res);
                    })
                }
            })
        },
    }
}