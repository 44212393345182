import {request,setAuthInHeader} from "@/api/request";

const state = {
    list: [],
    hasGet: 0, //0: 미요청, 1: 요청중, 2: 요청완료
};

const getters  = {
    getList: state => state.list,
    getHasGet: state => state.hasGet,
};

const mutations = {
    updateList(state, obj) {
        if(obj.key && obj.index) {
            state.list[index][key] = obj.data;
        }else if(obj.index){
            state.list[index] = obj.data;
        }else{
            state.list = obj.data;
        }
    },
    pushList(state, obj) {
        state.list.push(obj.data);
    },

    popList(state, obj) {
        state.list.splice(obj.data, 1);
    },
    updateHasGet(state, obj) {
        state.hasGet = obj.data;
    },
    resetList(state, obj) {
        state.list = [];
    }
};

const actions = {
    setList({commit, rootState}, {token}) {
        setAuthInHeader(token);
        let params = {
            ip: 0,
        }
        commit('updateHasGet', {data : 1});
        request.get('alarm/member', params)
            .then((res) => {
                commit('updateHasGet', {data : 2});
                let result = res.data.Data.Result;
                if(result.Info.type === 1 && result.List.length > 0) {
                    commit('updateList', {data : result.List, index: null, key: null});
                }
            }, () => {
                console.log('error');
            })
    },
    updateList({commit, rootState}, {data, index=null, key=null}){
        commit('updateList', {data:data, index: index, key: key});
    },
    initList({commit, rootState}, {}) {
        commit('resetList', {});
    },
    pushList({commit, rootState}, {data}) {
        commit('pushList', {data : data});
    },
    popList({commit, rootState}, {data}) {
        commit('popList', {data : data});
    },
    initHasGet({commit, rootState}, {}) {
        commit('updateHasGet', {data: 0});
    },
};

export default { namespaced: true, state, getters, mutations, actions }