import Vue from 'vue';
import SimpleVueValidation from 'simple-vue-validator';
import util from "@/mixins/util";

const Validator = SimpleVueValidation.Validator;


Vue.use(SimpleVueValidation);
export default {
    data() {
        return {
            type: 0,
            phone: '',
            phoneCertify: '',
            phoneCertifyAuth: '',
            id: '',
            name: '',
            email: '',
            birth: '',
            password: '',
            passwordChk: '',
            job: '',
            belong: '',
            alarmJob: [],
            introduce: '',
            parentName: '',
            parentBirth: '',
            parentPhone: '',
            parentPhoneCertify: '',
            parentPhoneCertifyAuth: '',
            files: null,
            prePassword: '',
            pushAlarm: 0,
            quitList: [],
            quitReason:'',
            agree:'',


            hasPhoneCheck: false,
            phoneOverlap: false,
            phoneCertifyFail: false,
            hasIdCheck: false,
            idOverlap: false,
            noIdCheck: false,
            hasParentPhoneCheck: false,
            parentPhoneOverlap: false,
            parentPhoneCertifyFail: false,
        }
    },
    validators: {
        type: function (value) {
            return Validator.value(value)
                .required('필수사항을 입력해주세요.')
        },
        phone: function (value) {
            return Validator.value(value)
                .required('전화번호를 입력해주세요.')
                .integer('전화번호를 입력해주세요.')
                .minLength(9, '전화번호를 입력해주세요.')
        },
        id: function (value) {
            return Validator.value(value)
                .required('아이디를 입력해주세요.')
                .regex('^[A-Za-z0-9+]*$', '아이디는 영문 숫자로만 입력가능합니다.')
                .minLength(2, '아이디는 4자이상 8자 이하로 입력이 가능합니다.')
        },
        name: function (value) {
            return Validator.value(value)
                .required("이름을 입력해주세요")
                .regex('^[ㄱ-ㅎ|가-힣|a-z|A-Z|\\*]+$',' 잘못된 형식입니다 <br>(2~5자, 영어, 한글만 가능, 띄어쓰기 불가능)')

        },
        email: function (value) {
            return Validator.value(value)
                .required('이메일을 입력해주세요.')
                .email('이메일 형식이 아닙니다.')
        },
        birth: function (value) {
            return Validator.value(value).required("생년월일을 입력해 주세요")

        },
        password: function (value) {
            return Validator.value(value)
                .required('비밀번호를 입력해주세요.')
                .minLength(8, ' 잘못된 형식입니다 <br>(8~16자, 대소문자구분, 특수문자가능)')
                .regex('^.*(?=.*[0-9])(?=.*[a-zA-Z]).*$',' 잘못된 형식입니다 <br>(8~16자, 대소문자구분, 특수문자가능)')
        },
        passwordChk: function (value) {
            return Validator.value(value)
                .required('비밀번호 확인을 입력해주세요.')
                .custom(
                    () => {
                        if(value != this.password) {
                            return '비밀번호가 일치하지 않습니다'
                        }
                    }
                )
        },
        job: function (value) {
            return Validator.value(value)
                .required('직업을 입력해주세요.')
        },
        belong: function (value) {
            return Validator.value(value)
                .required('소속을 입력해주세요.')
        },
        alarmJob: function (value) {
            return Validator.value(value)
                .required('알람받을 직업을 선택해주세요.')
        },
        introduce: function (value) {
            return Validator.value(value)
                .required('자기소개를 입력해주세요.')
        },
        phoneCertifyAuth: function (value) {
            return Validator.value(value)
                .required('전화번호 인증을 진행해주세요.')
        },
        phoneCertify: function (value) {
            return Validator.value(value)
                .required('전화번호 인증코드를 입력해주세요.')
        },
        hasIdCheck: function (value) {
            return Validator.value(value)
                .custom(() => {
                    if(value != true) {
                        return '아이디 중복체크를 진행해주세요.'
                    }
                })
        },
        parentName: function (value) {
            return Validator.value(value)
                .required("보호자 이름을 입력해주세요")
                .regex('^[ㄱ-ㅎ|가-힣|a-z|A-Z|\\*]+$',' 잘못된 형식입니다 <br>(2~5자, 영어, 한글만 가능, 띄어쓰기 불가능)')

        },
        parentBirth: function (value) {
            return Validator.value(value).required("보호자 생년월일을 입력해 주세요")

        },
        parentPhone: function (value) {
            return Validator.value(value)
                .required('전화번호를 입력해주세요.')
                .custom(() => {
                    if(value === this.phone) {
                        return '가입자와 동일한 전화번호입니다.'
                    }
                })
        },
        parentPhoneCertifyAuth: function (value) {
            return Validator.value(value)
                .required('전화번호 인증을 진행해주세요.')
        },
        parentPhoneCertify: function (value) {
            return Validator.value(value)
                .required('전화번호 인증코드를 입력해주세요.')
        },
        files: function (value) {
            return Validator.value(value)
                .required('프로필 사진을 등록해주세요.')

        },
        prePassword: function (value) {
            return Validator.value(value)
                .required('현재 비밀번호를 입력해주세요')

        },
        pushAlarm: function (value) {
            return Validator.value(value)
        },
        quitList: function (value) {
            return Validator.value(value)
                .required('탈퇴사유를 선택해 주세요')
        },
        quitReason: function (value) {
            return Validator.value(value)
                .required('탈퇴사유를 입력해 주세요')
        },
        agree : function (value) {
            return Validator.value(value)
                .required('약관에 동의해 주세요')
        },
    },
    methods: {
        initData() {
            this.type= 0;
            this.phone= '';
            this.phoneCertify= '';
            this.phoneCertifyAuth= '';
            this.id= '';
            this.name= '';
            this.email= '';
            this.birth= '';
            this.password= '';
            this.passwordChk= '';
            this.job= '';
            this.belong= '';
            this.alarmJob= [];
            this.introduce= '';
            this.parentName= '';
            this.parentBirth= '';
            this.parentPhone= '';
            this.parentPhoneCertify= '';
            this.parentPhoneCertifyAuth= '';
            this.files= null;
            this.prePassword= '';
            this.pushAlarm= 0;
            this.quitList= [];
            this.quitReason='';
            this.agree='';


            this.hasPhoneCheck= false;
            this.phoneOverlap= false;
            this.phoneCertifyFail= false;
            this.hasIdCheck= false;
            this.idOverlap= false;
            this.noIdCheck= false;
            this.hasParentPhoneCheck= false;
            this.parentPhoneOverlap= false;
            this.parentPhoneCertifyFail= false;
        },
        sendPhoneCertify() {

            this.$validate(['phone']).then(success => {
                if(!success) {
                    return false;
                }
                let params = {
                    type: 1,
                    mb_hp: this.phone,
                }
                this.$api.$member.sendPhoneCeritify(params).then(res => res.Data.Result).then(res => {
                    if(res.Info.type != 1) {

                        this.phoneOverlap = true;
                        return;
                    }
                    this.phoneCertifyAuth = res.List.auth_key;
                })
            })
        },
        checkPhoneCertify(nextFunc=null) {
            this.$validate(['phone', 'phoneCertify', 'phoneCertifyAuth']).then(success => {
                if(!success) {
                    return false;
                }
                let params = {
                    hc_code: this.phoneCertify,
                    auth_key: this.phoneCertifyAuth,
                }
                this.$api.$member.checkPhoneCertify(params).then(res => res.Data.Result).then(res => {
                    console.log(res.Info.type)
                    if(res.Info.type != 1) {
                        this.phoneCertifyFail = true;
                        return false;
                    }
                    this.phoneCertifyFail = false;
                    this.hasPhoneCheck = true;
                    if(nextFunc != null) {
                        nextFunc();
                    }
                })
            })
        },
        sendPaerntPhoneCertify() {
            this.$validate(['parentPhone']).then(success => {
                if(!success) {
                    return false;
                }
                let params = {
                    type: 2,
                    mb_hp: this.parentPhone,
                }
                this.$api.$member.sendPhoneCeritify(params).then(res => res.Data.Result).then(res => {
                    if(res.Info.type != 1) {
                        this.parentPhoneOverlap = true;
                        return;
                    }
                    this.parentPhoneCertifyAuth = res.List.auth_key;
                })
            })
        },
        checkPaerntPhoneCertify(nextFunc=null) {
            this.$validate(['parentPhone', 'parentPhoneCertify', 'parentPhoneCertifyAuth']).then(success => {
                if(!success) {
                    return false;
                }
                let params = {
                    hc_code: this.parentPhoneCertify,
                    auth_key: this.parentPhoneCertifyAuth,
                }
                this.$api.$member.checkPhoneCertify(params).then(res => res.Data.Result).then(res => {
                    if(res.Info.type != 1) {
                        this.parentPhoneCertifyFail = true;
                        return false;
                    }
                    this.parentPhoneCertifyFail = false;
                    this.hasParentPhoneCheck = true;
                    if(nextFunc != null) {
                        nextFunc();
                    }
                })
            })
        },
        checkIdOverLap() {
            this.$validate(['id']).then(success => {
                if(!success) {
                    return;
                }
                let params = {
                    mb_id: this.id,
                }
                try {
                    this.$api.$member.checkIdOverLap(params).then(res => res.Data.Result).then(res => {
                        if(res.Info.type != 1) {
                            this.idOverlap = true;
                            return;
                        }
                        this.noIdCheck = false;
                        this.idOverlap = false;
                        this.hasIdCheck = true;
                    })
                }catch (e) {
                    console.log(e)
                }
            })
        },
    }
}
