var Between = (function() {
	function Between() {
		this.filter = {
			key: null,
			start: null,
			end: null,
			classname: null,
		}
		Between.prototype.setterFilter = function(key=null, value=null, classname=null) { //여기서 value는 무조건 배열로 들어와야하는 전제조건.
			if(Array.isArray(value) && value.length < 2) {
				return;
			}
			this.filter.key = key;
			this.filter.start = value[0];
			this.filter.end = value[1];
			this.filter.classname = classname;
		};
		Between.prototype.parserReturnValue = function(value) {
			return value.split('^');
		}
	}
	return Between;
})();

export default Between;