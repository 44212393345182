import { storeSet, authPageCheck} from "@/mixins/Auth.js"

function setViewResolver(component) {
    return require('../views/' + component).default;
}
const layout = {
    render(h) { return h('router-view') }
}


export default [
    {
        path: '/',
        component: setViewResolver('Home'),
        name : 'Home',
        beforeEnter: storeSet,
        meta : { name : 'Home', title: '홈', authRequired: true, storeRequired: true, storeConfig:{userInfo:true, checkFinish: true, checkLevel: false,} },
    },{
        path: '/member/:mb_no',
        component: setViewResolver('member/MemberDetail'),
        name : 'MemberDetail',
        beforeEnter: storeSet,
        meta : { name : 'MemberDetail', title: '회원상세', authRequired: true, storeRequired: true, storeConfig:{userInfo:true, checkFinish: true, checkLevel: false,} },
    },{
        path: '/readyauth',
        component: setViewResolver('auth/ReadyAuth'),
        name: 'ReadyAuth',
        beforeEnter: storeSet,
        meta: {name: 'ReadyAuth', title: '회원가입완료', transitionName: 'none', authRequired: true, storeRequired: true, storeConfig:{userInfo:true}}
    },{
        path: '/info',
        component: setViewResolver('Info'),
        name: 'Info',
        meta: {name: 'Info', title: '인트로', transitionName: 'none'}
    },{
        path: '/privacy',
        component: setViewResolver('Privacy'),
        name: 'Privacy',
        meta: {name: 'Privacy', title: '개인정보취급방침', transitionName: 'none'}
    },{
        path: '/terms',
        component: setViewResolver('Terms'),
        name: 'Terms',
        meta: {name: 'Terms', title: '이용약관', transitionName: 'none'}
    },{
        path: '/auth',
        component: setViewResolver('auth/Login'),
        name: 'Login',
        beforeEnter: authPageCheck,
        meta: {name: 'Login', title: '로그인', transitionName: 'none'},
    },{
        path: '/auth/regist',
        component: setViewResolver('auth/Regist'),
        name: 'Regist',
        beforeEnter: authPageCheck,
        meta: {name: 'Login', title: '회원가입'},
    },{
        path: '/auth/findid',
        component: setViewResolver('auth/FindId'),
        name: 'FindId',
        beforeEnter: authPageCheck,
        meta: {name: 'Login', title: '아이디 찾기'},
    },{
        path: '/auth/findpw',
        component: setViewResolver('auth/FindPw'),
        name: 'FindPw',
        beforeEnter: authPageCheck,
        meta: {name: 'Login', title: '비밀번호 재발급'},
    },{
        path: '/commu/write',
        component: setViewResolver('commu/CommunityWrite'),
        name: 'CommunityWrite',
        beforeEnter: storeSet,
        meta: {name: 'Community', title: '소통 게시판 작성', authRequired: true, storeRequired: true, storeConfig:{checkLevel: true, checkFinish: true, userInfo:true,} }
    },{
        path: '/commu',
        component: setViewResolver('commu/Community'),
        name: 'Community',
        beforeEnter: storeSet,
        meta: {name: 'Community', title: '소통 게시판', authRequired: true, storeRequired: true, storeConfig:{checkLevel: true, checkFinish: true, userInfo:true,} },
    },{
        path: '/commu/:wr_id',
        component: setViewResolver('commu/CommunityDetail'),
        name: 'CommunityDetail',
        beforeEnter: storeSet,
        meta: {name: 'Community', title: '소통 게시판 상세',authRequired: true, storeRequired: true, storeConfig:{checkLevel: true, checkFinish: true, userInfo:true,} }
    },{
        path: '/commu/:wr_id/update',
        component: setViewResolver('commu/CommunityUpdate'),
        name: 'CommunityUpdate',
        beforeEnter: storeSet,
        meta: {name: 'Community', title: '소통 게시판 상세',authRequired: true, storeRequired: true, storeConfig:{checkLevel: true, checkFinish: true, userInfo:true,} }
    },{
        path: '/letter',
        component: setViewResolver('letter/Letter'),
        name: 'Letter',
        beforeEnter: storeSet,
        meta: {name: 'Letter', title: '꿈편지 검색',authRequired: true, storeRequired: true, storeConfig:{checkLevel: true, checkFinish: true, userInfo:true,} },
    },{

        path: '/letter/:wr_id',
        component: setViewResolver('letter/LetterDetail'),
        name: 'LetterDetail',
        beforeEnter: storeSet,
        meta: {name: 'Letter', title: '꿈편지 상세',authRequired: true, storeRequired: true, storeConfig:{checkLevel: true, checkFinish: true, userInfo:true,}},
    },{
        path: '/letter/:wr_id/update',
        component: setViewResolver('letter/LetterUpdate'),
        name: 'LetterUpdate',
        beforeEnter: storeSet,
        meta: {name: 'Letter', title: '꿈편지 상세',authRequired: true, storeRequired: true, storeConfig:{checkLevel: true, checkFinish: true, userInfo:true,} },
    },{
        path: '/mypage/:mb_no/profile',
        component: setViewResolver('mypage/Profile'),
        name: 'MypageProfile',
        beforeEnter: storeSet,
        meta: {name: 'MypageProfile', title: '내정보 수정',authRequired: true, storeRequired: true, storeConfig:{checkLevel: true, checkFinish: true, userInfo:true,}},
    },{
        path: '/alarm',
        component: setViewResolver('alarm/Alarm'),
        name: 'Alarm',
        beforeEnter: storeSet,
        meta: {name: 'Alarm', title: '내정보 수정', authRequired: true, storeRequired: true, storeConfig:{checkLevel: true, checkFinish: true, userInfo:true,}},
    },{
        path: '/mypage/:mb_no',
        component: setViewResolver('mypage/MyPage'),
        name: 'MyPage',
        beforeEnter: storeSet,
        meta: {name: 'MyPage', title: '내정보 수정', authRequired: true, storeRequired: true, storeConfig:{checkLevel: true, checkFinish: true, userInfo:true,}},
    },{
        path: '/mypage/:mb_no/:tab',
        component: setViewResolver('mypage/MyPage'),
        name: 'MyPage',
        beforeEnter: storeSet,
        meta: {name: 'MyPage', title: '내정보 수정', authRequired: true, storeRequired: true, storeConfig:{checkLevel: true, checkFinish: true, userInfo:true,}},
    },{
        path: '/alarm/:psl_idx',
        component: setViewResolver('alarm/AlarmDetail'),
        name: 'AlarmDetail',
        beforeEnter: storeSet,
        meta: {name: 'AlarmDetail', title: '알림내용', authRequired: true, storeRequired: true, storeConfig:{checkLevel: true, checkFinish: true, userInfo:true,}}
    },{
        path: '/quit',
        component: setViewResolver('quit/Quit'),
        name: 'Quit',
        beforeEnter: storeSet,
        meta: {name: 'Quit', title: '회원탈퇴', authRequired: true, storeRequired: true, storeConfig:{checkLevel: true, checkFinish: true, userInfo:true,}},
    },{
        path: '/notice',
        component: setViewResolver('notice/Notice'),
        name: 'Notice',
        meta: {name: 'Notice', title: '공지사항'},
    },{
        path: '/notice/:wr_id',
        component: setViewResolver('notice/NoticeDetail'),
        name: 'NoticeDetail',
        meta: {name: 'Notice', title: '공지사항 상세'},
    },{
        path: '/amidan',
        component: setViewResolver('amidan/Amidan'),
        name: 'Amidan',
        meta: {name: 'Amidan', title: '아미단활동 게시판'},
    },{
        path: '/amidan/:wr_id',
        component: setViewResolver('amidan/AmidanDetail'),
        name: 'AmidanDetail',
        meta: {name: 'Amidan', title: '아미단활동 게시판 상세'},
    },{
        path: '/faq',
        component: setViewResolver('faq/Faq'),
        name: 'Faq',
        meta: {name: 'Faq', title: 'FAQ'},
    },{
        path: '/terms',
        component: setViewResolver('terms/Terms'),
        name: 'Terms',
        meta: {name: 'Terms', title: '약관'},
    },{
        path: '/banner/:p_idx',
        component: setViewResolver('banner/BannerDetail'),
        name: 'BannerDetail',
        meta : { name : 'BannerDetail', title: '배너 상세', },
    },{
        path: '/mentor/write',
        component: setViewResolver('mentor/MentorCommunityWrite'),
        name: 'MentorCommunityWrite',
        beforeEnter: storeSet,
        meta: {name: 'MentorCommunity', title: '멘토 소통 게시판 작성', authRequired: true, storeRequired: true, storeConfig:{userInfo:true, checkFinish: true, checkLevel: true,}}
    },{
        path: '/mentor',
        component: setViewResolver('mentor/MentorCommunity'),
        name: 'MentorCommunity',
        beforeEnter: storeSet,
        meta: {name: 'MentorCommunity', title: '멘토끼리 소통 게시판', authRequired: true, storeRequired: true, storeConfig:{userInfo:true, checkFinish: true, checkLevel: true} },

    },{
        path: '/mentor/:wr_id',
        component: setViewResolver('mentor/MentorCommunityDetail'),
        name: 'MentorCommunityDetail',
        beforeEnter: storeSet,
        meta: {name: 'MentorCommunity', title: '소통 게시판 상세',authRequired: true, storeRequired: true, storeConfig:{checkLevel: true, checkFinish: true, userInfo:true,} }
    },{
        path: '/mentor/:wr_id/update',
        component: setViewResolver('mentor/MentorCommunityUpdate'),
        name: 'MentorCommunityUpdate',
        beforeEnter: storeSet,
        meta: {name: 'MentorCommunity', title: '소통 게시판 수정',authRequired: true, storeRequired: true, storeConfig:{checkLevel: true, checkFinish: true, userInfo:true,} }
    }
]
