import Swal from 'sweetalert2'

// Vue.use(AlertPlugin);
// Vue.use(ConfirmPlugin);

/*
alert 관련 메시지 이벤트 저장 후 사용
 */

export default {

    methods: {
        createAlert(params) {
            Swal.fire({
                title: params.title,
                html: params.content,
                confirmButtonText: params.btnText,
                focusConfirm:false,
                customClass :{
                    popup:'confirmPopup',
                    content :'confirmContent',
                    actions:'confirmAction',
                    confirmButton:'alertConfirm',
                },
                //check면 chk_alert 클래스를 주세요.
                onClose: () => {
                    if (params.back) {
                        window.history.back();
                    } else if (params.hide) {
                        params.hide();
                    }
                }
            })
        },
        createConfirm(params) {
            Swal.fire({
                title: params.title,
                html: params.content,
                confirmButtonText: params.confirmText,
                cancelButtonText: params.cancelText,
                showCloseButton: false,
                showCancelButton: true,
                focusConfirm:false,

                customClass :{
                    popup:'confirmPopup double',
                    content : 'confirmContent',
                    actions:'confirmAction',
                    confirmButton:'confirmConfirm',
                    cancelButton:'confirmCancel'

                }
            }).then((result) => {
                if (result.value) {
                    if (params.confirm)
                        params.confirm();
                } else {
                    if (params.cancel)
                        params.cancel();
                }
            })
        },
        errorAlert(params) {
            Swal.fire({
                title: '',
                html: params,
                confirmButtonText: '확인',
                customClass :{
                    popup:'confirmPopup',
                    content :'confirmContent',
                    actions:'confirmAction',
                    confirmButton:'alertConfirm',
                },
                onClose: () => {

                }
            })
        },
        declarateConfirm(params) {
            Swal.fire({
                title: '',
                customClass:'blind_popup',
                html: '신고사유 입력',
                confirmButtonText:  '확인',
                cancelButtonText: '취소',
                input : 'textarea',
                inputPlaceholder: '사유입력',
                inputAttributes: {
                    maxlength: 80,
                },
                inputAutoTrim: false,
                showCancelButton: true,
                // showCloseButton: true,
                inputValidator(inputValue) {
                    if(inputValue === '') {
                        return '사유를 입력해주세요.'
                    }
                }
            }).then((result) => {
                console.log(result)
                let reason = result.value
                if (result.value) {
                    if(params.confirm)
                        params.confirm(params.comment, reason);
                } else {
                    if(params.cancel)
                        params.cancel();
                }
            })
        },

        passwordChkAlert(params) {
            Swal.fire({
                title: '',
                customClass:'password_popup',
                html: '정보수정을 원하시면 비밀번호를 입력해주세요.',
                confirmButtonText:  '확인',
                input : 'password',
                inputPlaceholder: '비밀번호 입력',
                inputAttributes: {
                    maxlength: 16,
                },
                inputAutoTrim: false,
                showCancelButton: false,
                // showCloseButton: true,
                inputValidator(inputValue) {
                    if(inputValue === '') {
                        // return '비밀번호가 일치하지 않습니다.'
                        return '비밀번호를 입력해주세요.'
                    }
                    return params.checkPassword(inputValue).then(res => {
                        if(res === false) {
                            return '비밀번호가 일치하지 않습니다.'
                        }
                    })
                }
            }).then((result) => {
                console.log(result)
                let reason = result.value
                if (result.value) {
                    if(params.confirm)
                        params.confirm(reason);
                } else {
                    if(params.cancel)
                        params.cancel();
                }
            })
        },
    }
}
